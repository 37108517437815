import React from "react"
import {graphql} from "gatsby"
import MainLayout from "Layout/layout"
import IntroText from "Layout/IntroText/IntroText"
import AccordionList from "Layout/Lists/AccordionList/AccordionList"

const FellowshipProgram = ({
  data,
  location
}) => {

  const {page, optionsPage} = data
  const gI = optionsPage.edges[0].node.global_data.generic_images

  return (
    <MainLayout location={location}>
      <IntroText intro={page.acf.intro_text} headline={page.title} genericImages={gI} classes="py-5" />
      <AccordionList content={page.acf.accordion_list} />
    </MainLayout>
  )
}

export const query = graphql`
  query fellowshipProgramPageQuery {
    page: wordpressPage(wordpress_id: {eq: 22}) {
      title
      slug
      acf {
        intro_text {
          paragraphs {
            paragraph
          }
        }
        accordion_list {
          accordion_lists {
            headline
            items {
              name
              subline
              description
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 500) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    optionsPage: allWordpressAcfOptions {
      edges {
        node {
          global_data {
            generic_images {
              image {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default FellowshipProgram
